/** @format */

import { combineReducers } from "redux";
import AppReducer from "./AppReducer/AppReducer";
import { AppReducerInterface } from "./AppReducer/AppReducerInterface";

export interface RootState {
  app: AppReducerInterface;
}

export const rootReducer = combineReducers<RootState>({
  app: AppReducer,
});
