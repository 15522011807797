/** @format */

import { Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { createAsyncAction } from "typesafe-actions";
import { API } from "../Helpers/BaseUrlProvider";
import ActionTypes from "../constants/action_types";
import APIEndpoints from "../constants/api_endpoints";

const GET_COUNTRIES = createAsyncAction(
  ActionTypes.GET_COUNTRIES_REQUEST,
  ActionTypes.GET_COUNTRIES_SUCCESS,
  ActionTypes.GET_COUNTRIES_FAILURE
)<string, any, string>();

export const getCountriesEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GET_COUNTRIES.request),
    mergeMap((action) =>
      from(
        API.get(APIEndpoints.GET_COUNTRIES, {
          headers: { StoreId: action.payload?.StoreId },
        })
      ).pipe(
        mergeMap((response) => of(GET_COUNTRIES.success(response))),
        catchError((error) => of(GET_COUNTRIES.failure(error)))
      )
    )
  );
const MAKE_PAYMENT_SUBSCRIPTION = createAsyncAction(
  ActionTypes.MAKE_PAYMENT_SUBSCRIPTION_REQUEST,
  ActionTypes.MAKE_PAYMENT_SUBSCRIPTION_SUCCESS,
  ActionTypes.MAKE_PAYMENT_SUBSCRIPTION_FAILURE
)<string, any, string>();

export const makePaymentSubscriptionEpic: Epic = (action$) =>
  action$.pipe(
    ofType(MAKE_PAYMENT_SUBSCRIPTION.request),
    mergeMap((action) =>
      from(
        API.post(APIEndpoints.MAKE_PAYMENT_SUBSCRIPTION, action.payload, {
          headers: { StoreId: action.payload?.StoreId },
        })
      ).pipe(
        mergeMap((response) => of(MAKE_PAYMENT_SUBSCRIPTION.success(response))),
        catchError((error) => of(MAKE_PAYMENT_SUBSCRIPTION.failure(error)))
      )
    )
  );
const GET_PLAN_ITEMS_BY_SUBSCRIPTION = createAsyncAction(
  ActionTypes.GET_PLAN_ITEMS_BY_SUBSCRIPTION_ID_REQUEST,
  ActionTypes.GET_PLAN_ITEMS_BY_SUBSCRIPTION_ID_SUCCESS,
  ActionTypes.GET_PLAN_ITEMS_BY_SUBSCRIPTION_ID_FAILURE
)<string, any, string>();

export const getPlanItemsBySubscriptionIdEpic: Epic = (action$) =>
  action$.pipe(
    ofType(GET_PLAN_ITEMS_BY_SUBSCRIPTION.request),
    mergeMap((action) =>
      from(
        API.post(APIEndpoints.GET_PLAN_ITEMS_BY_SUBSCRIPTION, action.payload, {
          headers: { StoreId: action.payload?.StoreId },
        })
      ).pipe(
        mergeMap((response) =>
          of(GET_PLAN_ITEMS_BY_SUBSCRIPTION.success(response))
        ),
        catchError((error) => of(GET_PLAN_ITEMS_BY_SUBSCRIPTION.failure(error)))
      )
    )
  );
