/** @format */

import axios from "axios";

export const API = axios.create({
  baseURL:
    process.env.NODE_ENV == "production"
      ? `https://api.posapt.au`
      : `https://api.posapt.au`,
});
