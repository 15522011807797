/** @format */

import React from "react";
import { useLocation } from "react-router-dom";

interface POSHelper {
  getValueFromQuery: (queryKey: string) => string;
}

export function usePOSHelper(): POSHelper {
  function useQuery() {
    const { search } = useLocation();

    // Encode the '+' character as '%2B'
    const encodedSearch = search.replace(/\+/g, "%2B");

    return React.useMemo(
      () => new URLSearchParams(encodedSearch),
      [encodedSearch]
    );
  }
  const query = useQuery();

  //   const formatDate = (date: string) => {
  //     const dateFormat = new Date(date);
  //     const dateFormatISO = dateFormat.toISOString();
  //     const formattedDate = moment(dateFormatISO).format(
  //       homePageDataModel?.storeDetails?.dateFormat.toUpperCase().split(" ")[0] +
  //         " HH:mm:ss"
  //     );
  //     return formattedDate;
  //   };

  const getValueFromQuery = (queryKey: string) => {
    return query.get(queryKey) ?? "";
  };

  return {
    getValueFromQuery,
  };
}
