/** @format */

import { message } from "antd";
import ActionTypes from "../../../constants/action_types";
import { AppReducerInterface } from "./AppReducerInterface";

const initialState: AppReducerInterface = {
  isLoading: false,
};

const reducer = (state = initialState, action: any): AppReducerInterface => {
  switch (action.type) {
    case ActionTypes.GET_COUNTRIES_REQUEST:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countries: action.payload.data,
      };
    case ActionTypes.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.MAKE_PAYMENT_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        makePaymentSubscriptionLoading: true,
        isMakePaymentSubscriptionSuccess: false,
      };
    case ActionTypes.MAKE_PAYMENT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        makePaymentSubscriptionLoading: false,
        isMakePaymentSubscriptionSuccess: true,
      };
    case ActionTypes.MAKE_PAYMENT_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isMakePaymentSubscriptionSuccess: false,
        makePaymentSubscriptionLoading: false,
        error: action.payload?.response?.data?.message?.[0]?.message,
      };
    case ActionTypes.GET_PLAN_ITEMS_BY_SUBSCRIPTION_ID_REQUEST:
      message.loading("Loading..");
      return {
        ...state,
      };
    case ActionTypes.GET_PLAN_ITEMS_BY_SUBSCRIPTION_ID_SUCCESS:
      message.destroy();

      return {
        ...state,
        subscriptionPlansList: action.payload.data,
      };
    case ActionTypes.GET_PLAN_ITEMS_BY_SUBSCRIPTION_ID_FAILURE:
      message.destroy();
      return {
        ...state,
      };
    case ActionTypes.RESET_APP_REDUCER_STATE:
      return {
        ...state,
        error: null,
        isMakePaymentSubscriptionSuccess: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
