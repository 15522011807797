/** @format */

const APIEndpoints = {
  GET_COUNTRIES: "api/common/listCountryCityState",
  MAKE_PAYMENT_SUBSCRIPTION:
    "api/billingandsubscription/makePaymentSubscriptionAndBilling",
  GET_PLAN_ITEMS_BY_SUBSCRIPTION:
    "api/billingandsubscription/getPlanItemsBySubscriptionId",

  // ... add more endpoints as needed
};

export default APIEndpoints;
