/** @format */

import { CheckCircleOutlined, CheckOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Spin,
  Tag,
  Typography,
  message,
  theme,
} from "antd";
import { useEffect, useState } from "react";
import { Country } from "../../Redux/reducers/AppReducer/AppReducerInterface";
import ActionTypes from "../../constants/action_types";
import {
  EMAIL_VALIDATION_MESSAGE,
  EMPTY_FIELD_MESSAGE,
} from "../../constants/definitions";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { PaymentFormValues } from "../../types/commonInterfaces";
import isMobile from "../../utils/isMobile";
import { usePOSHelper } from "../../utils/usePOSHelper";
import {
  cardNumberValidator,
  cvcValidator,
  formatCVC,
  formatCreditCardNumber,
  formatExpirationDate,
} from "../../utils/validators";

const { Title } = Typography;
const { Header, Content, Footer } = Layout;

let processingModal: any;

const INITIAL_TITLE = "Processing Your Payment";
const INITIAL_CONTENT =
  "Processing your payment. This may take a moment. Please do not close or refresh the page.";
const INITIAL_ICON = (
  <Spin style={{ textAlign: "center", margin: "20px 0px" }} spinning={true} />
);

const Home: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [paymentForm] = Form.useForm();
  const dispatch = useAppDispatch();
  const {
    countries,
    subscriptionPlansList,
    isMakePaymentSubscriptionSuccess,
    makePaymentSubscriptionLoading,
    error,
  } = useAppSelector((state) => state.app);
  const { getValueFromQuery } = usePOSHelper();
  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>();
  const [title, setTitle] = useState<string>(INITIAL_TITLE);
  const [content, setContent] = useState<string>(INITIAL_CONTENT);
  const [icon, setIcon] = useState<any>(INITIAL_ICON);
  const [isProcessingModalVisible, setIsProcessingModalVisible] =
    useState<boolean>(false);
  const [isPaymentPopupClosable, setIsPaymentPopupClosable] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch({
      type: ActionTypes.GET_COUNTRIES_REQUEST,
      payload: {
        StoreId: getValueFromQuery("clientId"),
      },
    });
  }, []);

  const onPaymentFormSubmitHandler = (values: PaymentFormValues) => {
    setIsProcessingModalVisible(true);
    dispatch({
      type: ActionTypes.MAKE_PAYMENT_SUBSCRIPTION_REQUEST,
      payload: {
        Token: getValueFromQuery("token"),
        StoreId: getValueFromQuery("clientId"),
        CountryId: getValueFromQuery("countryId"),
        SubscriptionPlanId: getValueFromQuery("planId"),
        NumberOfPOSLocation: getValueFromQuery("nofoPos"),
        TotalAmount: getValueFromQuery("amount"),
        CreditCardDetails: {
          NameOnCard: values.name,
          CardNumber: values.cardNumber,
          CVCNumber: values.cvc,
          ExpiryMonth: values.expiry?.split("/")[0],
          ExpiryYear: values.expiry?.split("/")[1],
          Email: values.email,
        },
        BillingAddressDetails: {
          CountryId: values.countryId,
          CityId: values.cityId,
          StateId: values.stateId,
          Street: values.street,
          PostCode: values.postCode,
        },
        IsTermsAndConditionAccepted: true,
      },
    });
  };

  useEffect(() => {
    if (getValueFromQuery("planId")) {
      dispatch({
        type: ActionTypes.GET_PLAN_ITEMS_BY_SUBSCRIPTION_ID_REQUEST,
        payload: {
          Id: getValueFromQuery("planId"),
          StoreId: getValueFromQuery("clientId"),
        },
      });
    }
  }, [getValueFromQuery("planId")]);

  useEffect(() => {
    if (error) {
      setIsProcessingModalVisible(false);
      message.error(error);
      dispatch({
        type: ActionTypes.RESET_APP_REDUCER_STATE,
      });
    }
  }, [error]);

  useEffect(() => {
    if (isMakePaymentSubscriptionSuccess) {
      setTitle("Payment Completed ");
      setContent(
        "Congratulations! Your subscription plan payment was successful. Thank you for choosing our services. Enjoy the benefits of your subscription!"
      );
      setIcon(
        <CheckCircleOutlined style={{ fontSize: 80, color: "#52c41a" }} />
      );
      setIsPaymentPopupClosable(true);
      paymentForm.resetFields();
      dispatch({
        type: ActionTypes.RESET_APP_REDUCER_STATE,
      });
    }
  }, [isMakePaymentSubscriptionSuccess]);

  return (
    <Layout>
      <Modal
        title={title}
        visible={isProcessingModalVisible}
        onCancel={() => {
          setIsProcessingModalVisible(false);
          setTitle(INITIAL_TITLE);
          setContent(INITIAL_CONTENT);
          setIcon(INITIAL_ICON);
        }}
        footer={null}
        maskClosable={false}
        closable={isPaymentPopupClosable}
        closeIcon={isPaymentPopupClosable}
      >
        <div>
          <div className="modal-content-payment">{icon}</div>
          <p style={{ textAlign: "center" }}>{content}</p>
        </div>
      </Modal>
      <div
        style={{
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
          height: "13vh",
          background: "none",

          justifyContent: "center",
        }}
      >
        <div className="demo-logo">
          <img src="assets/images/logo.png" />
        </div>
      </div>
      {/* <Divider style={{ margin: "0" }} /> */}
      <Content className="site-layout">
        <div
          style={{
            marginTop: 30,
            padding: "0px 30px",
          }}
        >
          <Form
            form={paymentForm}
            onFinish={onPaymentFormSubmitHandler}
            labelAlign="left"
            labelCol={{
              span: 24,
            }}
            name="normal_login"
            className="col-md-12 row login-form"
          >
            <Row gutter={{ xs: 8, sm: 16, md: 44, lg: 44 }}>
              <Col xs={24} sm={24} md={24} lg={15} xl={15}>
                <Row gutter={0} className="left-box-payment">
                  <Col
                    className="box-payment"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                  >
                    <Card bordered={false} className="top-text-card">
                      <h2 className="text-header">
                        Welcome to POSApt <br />
                        Subscription Service!
                      </h2>
                      <p className="text-paragraph">
                        We are thrilled to welcome you to POSApt . It's our
                        pleasure to have you on board, and we're excited to
                        provide you with our exceptional services.
                      </p>
                    </Card>
                  </Col>

                  <Col
                    className="box-payment"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                  >
                    <div className="credit-card-form-heading">
                      <div className="accordion-body credit-card-form mt-3">
                        <Title
                          className=""
                          level={4}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "15px",
                            marginTop: "10px",
                          }}
                        >
                          Pay Now{" "}
                          <img
                            className="d-inline-block align-middle"
                            src="assets/images/card (1).png"
                            style={
                              !isMobile ? { width: 150 } : { display: "none" }
                            }
                            alt="Cerdit Cards"
                          />
                        </Title>
                        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 15]}>
                          <Col
                            className="gutter-row"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                          >
                            <Form.Item
                              label="Full name "
                              rules={[
                                {
                                  required: true,
                                  message: EMPTY_FIELD_MESSAGE,
                                },
                              ]}
                              name="name"
                            >
                              <Input
                                // value={name}
                                // onChange={(e) => setName(e.target.value)}
                                type="text"
                                name="username"
                                placeholder="Name on Card"
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            className="gutter-row"
                            span={12}
                          >
                            <Form.Item
                              className="w-100"
                              label="Card Number"
                              name="cardNumber"
                              rules={[
                                {
                                  required: true,
                                  message: EMPTY_FIELD_MESSAGE,
                                },
                                {
                                  validator: cardNumberValidator,
                                },
                              ]}
                            >
                              <Input
                                onChange={(e) => {
                                  paymentForm.setFieldValue(
                                    "cardNumber",
                                    formatCreditCardNumber(e.target.value)
                                  );

                                  // setCardNumber(e.target.value);
                                }}
                                type="text"
                                name="cardNumber"
                                placeholder="Your card number"
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            className="gutter-row"
                            span={12}
                          >
                            <Form.Item
                              label={"Expiry"}
                              name="expiry"
                              rules={[
                                {
                                  required: true,
                                  message: EMPTY_FIELD_MESSAGE,
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                onChange={(e) => {
                                  paymentForm.setFieldValue(
                                    "expiry",
                                    formatExpirationDate(e.target.value)
                                  );
                                }}
                                placeholder="MM/YY"
                                name=""
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            className="gutter-row"
                            span={12}
                          >
                            <Form.Item
                              name="cvc"
                              rules={[
                                {
                                  validator: cvcValidator,
                                },
                                {
                                  required: true,
                                  message: EMPTY_FIELD_MESSAGE,
                                },
                              ]}
                              label="CVC"
                            >
                              <Input
                                type="text"
                                placeholder="CVC"
                                onChange={(e) => {
                                  paymentForm.setFieldValue(
                                    "cvc",
                                    formatCVC(e.target.value, undefined)
                                  );
                                }}
                                // value={cvc}
                                // onChange={(e) => setCvc(e.target.value)}
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            className="gutter-row"
                            span={12}
                          >
                            <Form.Item
                              label="Email"
                              name={"email"}
                              rules={[
                                {
                                  type: "email",
                                  message: EMAIL_VALIDATION_MESSAGE,
                                },
                                {
                                  required: true,
                                  message: EMPTY_FIELD_MESSAGE,
                                },
                              ]}
                            >
                              <Input placeholder="Your Email" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div className="ms-2">
                      <div className="accordion-body mt-3 credit-card-form ">
                        <Title
                          level={4}
                          style={{ marginBottom: "22px", marginTop: "10px" }}
                          className="credit-card-form-heading"
                        >
                          Billing Address
                        </Title>
                        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 15]}>
                          <Col
                            className="gutter-row"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                          >
                            <Form.Item
                              label="Address"
                              name="street"
                              rules={[
                                {
                                  required: true,
                                  message: EMPTY_FIELD_MESSAGE,
                                },
                              ]}
                            >
                              <Input type="text" placeholder="Address" />
                            </Form.Item>
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            className="gutter-row"
                            span={12}
                          >
                            <Form.Item
                              label="Country"
                              name="countryId"
                              rules={[
                                {
                                  required: true,
                                  message: EMPTY_FIELD_MESSAGE,
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select Country"
                                onChange={(e) =>
                                  setSelectedCountry(
                                    countries?.find(
                                      (country) => country.id == e
                                    )
                                  )
                                }
                              >
                                {countries?.map((country) => {
                                  return (
                                    <Select.Option
                                      key={country.id}
                                      value={country.id}
                                    >
                                      <img
                                        src={country.image}
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                          marginRight: "7px",
                                        }}
                                      />
                                      {country.name}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            className="gutter-row"
                            span={12}
                          >
                            <Form.Item
                              label="State"
                              name="stateId"
                              rules={[
                                {
                                  required: true,
                                  message: EMPTY_FIELD_MESSAGE,
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select State"
                                options={selectedCountry?.states.map(
                                  (state) => {
                                    return {
                                      label: state.value,
                                      value: state.id,
                                    };
                                  }
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            className="gutter-row"
                            span={12}
                          >
                            <Form.Item
                              label="City"
                              name="cityId"
                              rules={[
                                {
                                  required: true,
                                  message: EMPTY_FIELD_MESSAGE,
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select City"
                                options={selectedCountry?.cities.map(
                                  (state) => {
                                    return {
                                      label: state.value,
                                      value: state.id,
                                    };
                                  }
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            className="gutter-row"
                            span={12}
                          >
                            <Form.Item
                              label="Postal Code"
                              name="postCode"
                              rules={[
                                {
                                  required: true,
                                  message: EMPTY_FIELD_MESSAGE,
                                },
                              ]}
                            >
                              <Input type="text" placeholder="Postal Code" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                <Row className="payment-row">
                  <Col>
                    <Card title="Payment Summary" bordered={false}>
                      {/* <Tag
                        className="payment-tag"
                        bordered={false}
                        color="magenta"
                      >
                        No. Of POS Device : {10}
                      </Tag> */}
                      <p className="card-key">
                        <Tag
                          className="payment-tag"
                          bordered={false}
                          color="magenta"
                        >
                          {getValueFromQuery("planName")}
                        </Tag>{" "}
                      </p>
                      <p>
                        <span className="card-key">No. Of POS Device:</span>{" "}
                        <span>{getValueFromQuery("nofoPos")}</span>{" "}
                      </p>
                      <p>
                        <span className="card-key">Total Amount:</span>{" "}
                        <span className="amount">
                          {" "}
                          $ {getValueFromQuery("amount")}
                        </span>{" "}
                      </p>
                      <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Should accept Privacy Policy and Terms Of Service"
                                    )
                                  ),
                          },
                        ]}
                      >
                        <Checkbox>
                          I have read the{" "}
                          <a
                            target="_blank"
                            href="https://posapt.au/privacy-policy"
                          >
                            Privacy Policy
                          </a>{" "}
                          and{" "}
                          <a
                            target="_blank"
                            href="https://posapt.au/terms-of-service"
                          >
                            Terms of Service
                          </a>
                        </Checkbox>
                      </Form.Item>
                      <Button
                        style={{ width: "100%" }}
                        type="primary"
                        htmlType="submit"
                      >
                        PROCESS PAYMENT
                      </Button>
                    </Card>
                  </Col>
                  <Col>
                    <Card title="Included Plans" bordered={false}>
                      <div className="container-check-icon-list">
                        {subscriptionPlansList?.map((item: string, i) => {
                          return (
                            <div className="container-check-icon-text" key={i}>
                              <CheckOutlined className="check-icon" />
                              <li
                                style={{
                                  listStyle: "none",
                                }}
                                key={i + item}
                              >
                                {item}
                              </li>
                            </div>
                          );
                        })}
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>

      <footer className=" p-2 mt-0 bg-darker footer-2">
        <div className="">
          {/* Bottom Credits */}
          <div className="col-md-12 text-center">
            <p className="fw-bold footer-text">
              COPYRIGHT © 2023 , Designed by{" "}
              <a
                href="https://posapt.au"
                target={"_blank"}
                className="footer-text"
              >
                POSApt
              </a>
            </p>
          </div>
        </div>
        {/* /container */}
        {/* Go To Top Link */}
      </footer>
    </Layout>
  );
};

export default Home;
