/** @format */

import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Routes_Constants from "../constants/routes_configs";
import Home from "../pages/Home/Home";

const RoutesConfigs: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path={Routes_Constants.HOME} element={<Home />} />
      </Routes>
    </Router>
  );
};

export default RoutesConfigs;
