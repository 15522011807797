/** @format */

const ActionTypes = {
  GET_COUNTRIES_REQUEST: "GET_COUNTRIES_REQUEST",
  GET_COUNTRIES_SUCCESS: "GET_COUNTRIES_SUCCESS",
  GET_COUNTRIES_FAILURE: "GET_COUNTRIES_FAILURE",

  MAKE_PAYMENT_SUBSCRIPTION_REQUEST: "MAKE_PAYMENT_SUBSCRIPTION_REQUEST",
  MAKE_PAYMENT_SUBSCRIPTION_SUCCESS: "MAKE_PAYMENT_SUBSCRIPTION_SUCCESS",
  MAKE_PAYMENT_SUBSCRIPTION_FAILURE: "MAKE_PAYMENT_SUBSCRIPTION_FAILURE",

  GET_PLAN_ITEMS_BY_SUBSCRIPTION_ID_REQUEST:
    "GET_PLAN_ITEMS_BY_SUBSCRIPTION_ID_REQUEST",
  GET_PLAN_ITEMS_BY_SUBSCRIPTION_ID_SUCCESS:
    "GET_PLAN_ITEMS_BY_SUBSCRIPTION_ID_SUCCESS",
  GET_PLAN_ITEMS_BY_SUBSCRIPTION_ID_FAILURE:
    "GET_PLAN_ITEMS_BY_SUBSCRIPTION_ID_FAILURE",

  RESET_APP_REDUCER_STATE: "RESET_APP_REDUCER_STATE",

  // ... add more action types
};

export default ActionTypes;
