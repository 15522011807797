/** @format */

import { combineEpics } from "redux-observable";
import {
  getCountriesEpic,
  getPlanItemsBySubscriptionIdEpic,
  makePaymentSubscriptionEpic,
} from "./myEpics";

export const rootEpic = combineEpics(
  getCountriesEpic,
  makePaymentSubscriptionEpic,
  getPlanItemsBySubscriptionIdEpic
);
